import React, { useState } from 'react'
import { Link } from '@reach/router'
import kidpilotLogo from '../images/logohead@2x.png'
import MobileMenu from './navigation/mobileMenu/MobileMenu'
import DesktopMenu from './navigation/desktopMenu/DesktopMenu'
import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const SiteLogo = styled('div')`
  position: fixed;
  z-index: 11;
  top: 0.8rem;
  a {
    padding: 1rem;
  }
  img {
    width: 35px;
    height: 35px;
    transition: transform 0.3s;
    :hover {
      transform: rotate(12deg);
    }
  }

  @media (min-width: 767px) {
    top: 1.8rem;
    img {
        // width: ${({ scrolled }) => (scrolled ? '30px' : '45px')};
        // height: ${({ scrolled }) => (scrolled ? '30px' : '45px')};
      width: 45px;
      height: 45px;
      margin-left: 20px;
    }
  }
`

const Header = () => {
  const [scrolled, setScrolled] = useState(false)
  const [clickedLogo, setClickedLogo] = useState(false)
  const breakpoints = useBreakpoint()

  const setClicked = () => {
    setClickedLogo(!clickedLogo)
  }

  // TODO aktivera nedanstående om vi vill ha scrolled state på menyn
  // useEffect(
  //   () => {
  //     const handleScroll = () => {
  //       const scrollPosition = window.pageYOffset
  //       if (scrollPosition > 155 && !scrolled) {
  //         setScrolled(true)
  //       } else if (scrollPosition <= 155 && scrolled) {
  //         setScrolled(false)
  //       }
  //     }
  //     document.addEventListener('scroll', handleScroll)
  //     return () => {
  //       document.removeEventListener('scroll', handleScroll)
  //     }
  //   },
  //   [scrolled]
  // )

  return (
    <div>
      <SiteLogo onClick={setClicked} scrolled={scrolled}>
        <Link to="/">
          <img src={kidpilotLogo} alt="Kidpilot Logo" />
        </Link>
      </SiteLogo>
      {breakpoints.mobile ? (
        <MobileMenu clickedLogo={clickedLogo} />
      ) : (
        <DesktopMenu clickedLogo={clickedLogo} />
      )}
    </div>
  )
}
export default Header
