import * as React from 'react'
import { useRef, useEffect, useState } from 'react'
import { motion, useCycle } from 'framer-motion'
import MenuToggle from './MenuToggle'
import { Navigation } from './Navigation'

const useDimensions = ref => {
  const dimensions = useRef({ width: 0, height: 0 })

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth
    dimensions.current.height = ref.current.offsetHeight
  }, [])

  return dimensions.current
}

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 40px) 40px)`,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(30px at calc(100% - 40px) 40px)',
    opacity: 0,
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

const navigation = {
  open: {
    display: 'flex',
    transition: {
      when: 'beforeChildren',
    },
  },
  closed: {
    display: 'none',
    transition: {
      when: 'afterChildren',
    },
  },
}

const MobileMenu = ({ clickedLogo }) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)
  const [expanded, setExpanded] = useState({})

  const toggleMobileMenu = () => {
    const bodyElement = document.querySelector('body')
    toggleOpen()
    if (!isOpen) {
      bodyElement.style.overflow = 'hidden'
    } else {
      setExpanded({})
      bodyElement.style.overflow = 'unset'
    }
  }

  useEffect(() => {
    if (isOpen) {
      toggleMobileMenu()
    }
  }, [clickedLogo])

  return (
    <>
      <motion.nav
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        custom={height}
        ref={containerRef}
        className="mobileMenu"
        variants={navigation}
      >
        <motion.div className="background" variants={sidebar} />
        <Navigation
          toggleOpen={toggleMobileMenu}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </motion.nav>
      <MenuToggle isOpen={isOpen} toggle={() => toggleMobileMenu()} />
    </>
  )
}
export default MobileMenu
